
import { defineComponent, reactive, ref, onMounted, toRefs } from "vue";
import {
  getVehicleViews,
} from "@/API/systemVehicle/systemVehicleIndex";
// import useFetchCommon from "@/hooks/useFetchCommon";
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import useTableHeight from "@/hooks/useTableHeight";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import { VehicleInfoType } from './types'
import { wholesaleDetailColumns, vehicleInfoColumns, vehicleModelColumns, retailDetailColumns, billingTypeMap } from './tableColumns'

export default defineComponent({
  name: "DealerView",
  components: {
  },
  setup() {
    const route = useRoute();
    // const { back } = useRouter();
    const { tableHeight } = useTableHeight();

    const vehicleInfo: VehicleInfoType = reactive({
      colorDescription: "",
      commissionNumber: "",
      displacement: "",
      engineNumber: "",
      fin: "",
      trimColorDescription: "",
      vehicleUsage: "",
      vehicleUsageDescription: "",
      vin: "",
    })

    const vehicleModelInfo: any = reactive({
      brand: '',
      typeClass: '',
      modelNameEn: '',
      make: '',
      materialNo: '',
      modelNameCn: '',
      modelYear: '',
      powerTrainName: ''
    })

    const wholesaleInfo: any = reactive({
      wholesaleDate: '',
      firstWholesaleDate: '',
      billingDocument: '',
      wholesaleDealerCode: '',
      wholesaleDealerNameEn: '',
      wholesaleDealerNameCn: '',
      firstDealerCode: '',
      firstDealerNameEn: '',
      firstDealerNameCn: '',
      totalNetPrice: '',
      vatAmount: '',
      vatPercentage: '',
      transportCosts: '',
      cashDiscount: '',
    })

    const retailInfo: any = reactive({
      retailDate: '',
      registrationDate: '',
      acquisitionInvoiceDate: "",
      acquisitionRegistrationDate: "",
      retailDealerCode: '',
      retailDealerNameCn: '',
      retailDealerNameEn: '',
      factoryManufacturerRetailPrice: '',
      manufacturerRetailPrice: '',
      totalGrossPrice: '',
      totalRetailPrice: '',
    })

    const wholesaleDetailData = ref(null)

    const retailDetailData = ref(null)

    const getData = (targetData: any, sourceData: any) => {
      for (const key in targetData) {
        if (Object.prototype.hasOwnProperty.call(sourceData, key)) {
          targetData[key] = sourceData[key]
        }
      }
      return targetData;
    }

    //获取页面数据
    const getViewsIndex = () => {
      const params = {
        vehicleMasterId: route.query.vehicleMasterId
      };
      getVehicleViews(params).then((res) => {
        getData(vehicleInfo, res.vehicleInfo);
        getData(vehicleModelInfo, res.vehicleModelInfo);    
        getData(wholesaleInfo, res.wholesaleInfo);
        getData(retailInfo, res.retailInfo);
        wholesaleDetailData.value = res.wholesaleDetailList
        retailDetailData.value = res.retailDetailList
      });
    };

    onMounted(() => {
      getViewsIndex();
    });

    return {
      vehicleInfoColumns,
      vehicleModelColumns,
      tableHeight,
      vehicleInfo,
      vehicleModelInfo,
      wholesaleInfo,
      retailInfo,
      wholesaleDetailData,
      wholesaleDetailColumns,
      retailDetailData,
      retailDetailColumns,
      billingTypeMap,
      toThousandsAndTwoDecimal,
      route,
    };
  },
});
