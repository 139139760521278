import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";

const columns = [
  {
    title: "Commission Number",
    dataIndex: "commissionNumber",
    width: 150,
  },
  {
    title: "VIN",
    dataIndex: "vin",
    width: 180,
  },
  {
    title: "Material No",
    dataIndex: "materialNo",
    width: 150,
  },
  {
    title: "Model Name",
    width: 220,
    slots: { customRender: 'modelName' },
  },
  {
    title: "Model Year",
    dataIndex: "modelYear",
    width: 100,
  },
  {
    title: "Engine Number",
    dataIndex: "engineNumber",
    width: 150,
  },
  {
    title: "Vehicle Usage Description",
    dataIndex: "vehicleUsageDescription",
    width: 240,
  },
  {
    title: "Wholesale Date",
    dataIndex: "wholesaleDate",
    width: 150,
  },
  {
    title: "Retail Date",
    dataIndex: "retailDate",
    width: 150,
  },
  {
    fixed: 'right',
    title: "Operation",
    dataIndex: "operation",
    slots: { customRender: "operation" },
    width: 85,
  },
]

const vehicleInfoColumns = [
  {
    name: 'VIN',
    key: 'vin'
  },
  {
    name: 'Commission Number',
    key: 'commissionNumber'
  },
  {
    name: 'FIN',
    key: 'fin'
  },
  {
    name: 'Engine Number',
    key: 'engineNumber'
  },
  {
    name: 'Vehicle Usage',
    key: 'vehicleUsage'
  },
  {
    name: 'Color Description',
    key: 'colorDescription'
  },
  {
    name: 'Displacement',
    key: 'displacement'
  },
  {
    name: 'Vehicle Usage Description',
    key: 'vehicleUsageDescription'
  },
  {
    name: 'Trim Color Description',
    key: 'trimColorDescription'
  },
]

const vehicleModelColumns = [
  {
    name: 'Brand',
    key: 'brand'
  },
  {
    name: 'Type Class',
    key: 'typeClass'
  },
  {
    name: 'Model Name_EN',
    key: 'modelNameEn'
  },
  {
    name: 'Make',
    key: 'make'
  },
  {
    name: 'Material No',
    key: 'materialNo'
  },
  {
    name: 'Model Name_CN',
    key: 'modelNameCn'
  },
  {
    name: 'Model Year',
    key: 'modelYear'
  },
  {
    name: 'Power Train',
    key: 'powerTrainName'
  }
]

const wholesaleDetailColumns = [
  {
    title: "Billing Type",
    width: 150,
    dataIndex: "billingType",
    slots: { customRender: 'billingType' },
  },
  {
    title: "Date",
    dataIndex: "createdTime",
    width: 150,
  },
  {
    title: "Dealer Code",
    dataIndex: "dealerCode",
    width: 110,
  },
  {
    title: "Dealer Name",
    width: 200,
    slots: { customRender: 'dealerName' },
  },
  {
    title: "Total Net Price",
    dataIndex: "totalNetPrice",
    width: 140,
    customRender: useThousandsAndTwoDecimal(),
  },
  {
    title: "Vat Percentage",
    dataIndex: "vatPercentage",
    width: 140,
  },
  {
    title: "Transport Costs",
    dataIndex: "transportCosts",
    width: 180,
  },
  {
    title: "Manufacturer Retail Price",
    dataIndex: "manufacturerRetailPrice",
    width: 180,
    customRender: useThousandsAndTwoDecimal(),
  },
  {
    title: "Total Retail Price",
    dataIndex: "totalRetailPrice",
    width: 180,
    customRender: useThousandsAndTwoDecimal(),
  }
];

const retailDetailColumns = [
  {
    title: "Billing Type",
    dataIndex: "billingType",
    width: 150,
    slots: { customRender: 'billingType' },
  },
  {
    title: "Date",
    dataIndex: "createdTime",
    width: 150,
  },
  {
    title: "Dealer Code",
    dataIndex: "dealerCode",
    width: 110,
  },
  {
    title: "Dealer Name",
    width: 200,
    slots: { customRender: 'dealerName' },
  },
  {
    title: "Customer Fapiao Date",
    dataIndex: "customerInvoiceDate",
    width: 160,
  },
  {
    title: "Registration Date",
    dataIndex: "registrationDate",
    width: 150,
  },
  {
    title: "Acquisition Fapiao Date",
    dataIndex: "acquisitionInvoiceDate",
    width: 180,
  },
  {
    title: "Acquisition Registration Date",
    dataIndex: "acquisitionRegistrationDate",
    width: 220,
  }
];

const billingTypeMap = {
  'F2': 'Normal Sale',
  'RE': 'Cancel',
  'L2': 'Price Change'
}
export { columns, wholesaleDetailColumns, vehicleInfoColumns, vehicleModelColumns, retailDetailColumns, billingTypeMap };