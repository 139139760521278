import { h } from 'vue'
import { toThousandsAndTwoDecimal } from './numberFormat'
const useThousandsAndTwoDecimal = () => {
    return ({text}: {
        text: number | string;
    }) => {
        return h('span', toThousandsAndTwoDecimal(text));
    };
  };
  export default useThousandsAndTwoDecimal;
  